import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n  .lock-html {\n    overflow: hidden;\n  }\n  .lock-body {\n    position: fixed !important;\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    overflow: hidden;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }

import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { color } from "styled-system";
var GlobalStyle = createGlobalStyle(_templateObject());
var ViewportLockContainer = styled("div").withConfig({
  displayName: "src__ViewportLockContainer",
  componentId: "sc-1vm43vp-0"
})(["position:relative;overflow:hidden;", ""], color);

var ViewportLock = function ViewportLock(_ref) {
  var children = _ref.children,
      restProps = _objectWithoutPropertiesLoose(_ref, ["children"]);

  var _useState = useState(0),
      height = _useState[0],
      setHeight = _useState[1];

  useEffect(function () {
    if (!window) return;
    document.body.classList.add("lock-body");
    document.documentElement.classList.add("lock-html");

    var lockScroll = function lockScroll() {
      // Prevent window scrolling on iOS
      window.scrollTo(0, 0);
    };

    var handleResize = function handleResize() {
      setHeight(window.innerHeight);
    };

    window.addEventListener("scroll", lockScroll);
    window.addEventListener("resize", handleResize);
    handleResize();
    return function () {
      document.body.classList.remove("lock-body");
      document.documentElement.classList.remove("lock-html");
      window.removeEventListener("scroll", lockScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return React.createElement(ViewportLockContainer, Object.assign({
    style: {
      height: height
    }
  }, restProps), React.createElement(GlobalStyle, null), children);
};

export default ViewportLock;