import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import ViewportLock from "../../../packages/ViewportLock/src";
import Div from "../../../packages/Div/src";
import Flex from "../../../packages/Flex/src";
import Icon from "../../../packages/Icon/src";
import Button from "../../../packages/Button/src";
import Switch from "../../../packages/Switch/src";
import { Heading, P } from "../../../packages/Typography/src";
import { DrawerContainer, DrawerToggle, Drawer } from "../../../packages/Drawer/src";

var ImmersiveStoryDrawerPattern = function ImmersiveStoryDrawerPattern() {
  return React.createElement(PatternLayout, {
    minimal: true,
    navColor: "white",
    logoOutlined: true
  }, React.createElement(ViewportLock, {
    bg: "grey.900",
    color: "white"
  }, React.createElement(Flex, {
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  }, React.createElement(Div, {
    textAlign: "center"
  }, "Content")), React.createElement(Div, {
    position: "absolute",
    bottom: "0",
    right: "0",
    mr: "1.5rem",
    mb: "1.5rem",
    color: "text"
  }, React.createElement(DrawerContainer, {
    name: "DrawerContainer"
  }, React.createElement(DrawerToggle, {
    as: Button,
    variant: "primary",
    raised: true,
    borderRadius: "circle",
    width: "4rem",
    height: "4rem",
    px: "0"
  }, React.createElement(Icon, {
    name: "sliders"
  })), React.createElement(Drawer, {
    p: "1.25rem"
  }, React.createElement(Heading, {
    size: "xl"
  }, "Settings"), React.createElement(P, null, "This is some sample content for this drawer. You can put controls, descriptions, or anything else you want in here."), React.createElement(Flex, {
    my: "1rem",
    alignItems: "center"
  }, React.createElement(Div, {
    flex: "1 1 auto"
  }, "First option"), React.createElement(Switch, {
    size: "sm",
    name: "first-option",
    onChange: function onChange() {
      return console.log("Switched");
    }
  })), React.createElement(Flex, {
    my: "1rem",
    alignItems: "center"
  }, React.createElement(Div, {
    flex: "1 1 auto"
  }, "Second option"), React.createElement(Switch, {
    size: "sm",
    name: "second-option",
    onChange: function onChange() {
      return console.log("Switched");
    }
  })), React.createElement(DrawerToggle, {
    as: Button,
    variant: "primary",
    borderRadius: "pill",
    width: "100%",
    mt: "2.5rem"
  }, React.createElement(Icon, {
    name: "check",
    size: "1rem",
    mr: "0.5rem"
  }), "Apply"))))));
};

export default ImmersiveStoryDrawerPattern;